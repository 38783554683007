import React from 'react'
import classes from "./ClemEcosystem.module.scss"

const ClemEcosystem = () => {
  return (
    <div className={classes['clem-ecosystem-container-parent']}>
        <div className={classes['curve-white-comtainer']}>
            <div className={classes['clem-ecosystem-heading']}>CLEM Ecosystem</div>
            <div className={classes['divider']}></div>

            <div className={classes['clem-ecosystem-image']} >
                <img src="https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/Group+1410121422.svg" className={classes['clem-image']} alt="" />
            </div>
        </div>

    </div>
  )
}

export default ClemEcosystem