import React from "react";
import classes from "./ClemVision.module.scss";
import Arrow from "../../../assets/icons/Arrow";
import { useNavigate } from "react-router-dom";

const cardData = [
  {
    heading: "CLEM Enablement",
    url: "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/Group+1410121423.jpg",
    route: '/enablement',
  },
  {
    heading: "CLEM Transition",
    url: "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/Group+1410121424.jpg",
    route: '/transitions',
  },
];

const ClemVision = () => {
  const navigate = useNavigate();

  const handleCardClick = (route:any) => {
    navigate(route); 
  };
  return (
    <div className={classes["clem-vision-container-parent"]}>
      <div className={classes["curve-white-container"]}>
        <div className={classes["clem-vision-heading"]}>CLEM Vision</div>
        <div className={classes["divider"]}></div>

        <div className={classes["clem-text-container"]}>
          <div className={classes["clem-text"]}>
            CLEM's objective is to prioritize <span> profitability </span> while
            enabling and accelerating clean energy technologies.
          </div>
        </div>

        <div className={classes["clem-card-container-parent"]}>
          {cardData.map((card, index) => (
            <div
              key={index}
              className={classes["clem-enablement-card-conatiner"]}
              style={{
                backgroundImage: `url('${card.url}')`,
              }}
              onClick={() => handleCardClick(card.route)}
            >
              <div className={classes["navigate-icon"]}>
                <Arrow
                  arrowType="inverted"
                  color="white"
                  width="20"
                  height="20"
                />
              </div>
              <div className={classes["heading-container"]}>{card.heading}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClemVision;
