import React from 'react'

const WaterEc = () => {
  return (
    <svg width="31" height="39" viewBox="0 0 31 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.625 30.75H15.5L23 19.5H17.375V12H15.5L8 23.25H13.625V30.75ZM15.5 38.25C11.2188 38.25 7.64844 36.7812 4.78906 33.8438C1.92969 30.9062 0.5 27.25 0.5 22.875C0.5 19.75 1.74219 16.3516 4.22656 12.6797C6.71094 9.00781 10.4688 5.03125 15.5 0.75C20.5312 5.03125 24.2891 9.00781 26.7734 12.6797C29.2578 16.3516 30.5 19.75 30.5 22.875C30.5 27.25 29.0703 30.9062 26.2109 33.8438C23.3516 36.7812 19.7812 38.25 15.5 38.25ZM15.5 34.5C18.75 34.5 21.4375 33.3984 23.5625 31.1953C25.6875 28.9922 26.75 26.2188 26.75 22.875C26.75 20.5938 25.8047 18.0156 23.9141 15.1406C22.0234 12.2656 19.2188 9.125 15.5 5.71875C11.7812 9.125 8.97656 12.2656 7.08594 15.1406C5.19531 18.0156 4.25 20.5938 4.25 22.875C4.25 26.2188 5.3125 28.9922 7.4375 31.1953C9.5625 33.3984 12.25 34.5 15.5 34.5Z" fill="#1868DB"/>
    </svg>
    
  )
}

export default WaterEc