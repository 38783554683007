import React from 'react'

const PersonIcon = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 15.5C13.4375 15.5 11.6719 14.7656 10.2031 13.2969C8.73438 11.8281 8 10.0625 8 8C8 5.9375 8.73438 4.17188 10.2031 2.70313C11.6719 1.23438 13.4375 0.5 15.5 0.5C17.5625 0.5 19.3281 1.23438 20.7969 2.70313C22.2656 4.17188 23 5.9375 23 8C23 10.0625 22.2656 11.8281 20.7969 13.2969C19.3281 14.7656 17.5625 15.5 15.5 15.5ZM0.5 30.5V25.25C0.5 24.1875 0.773437 23.2109 1.32031 22.3203C1.86719 21.4297 2.59375 20.75 3.5 20.2812C5.4375 19.3125 7.40625 18.5859 9.40625 18.1016C11.4062 17.6172 13.4375 17.375 15.5 17.375C17.5625 17.375 19.5938 17.6172 21.5938 18.1016C23.5938 18.5859 25.5625 19.3125 27.5 20.2812C28.4063 20.75 29.1328 21.4297 29.6797 22.3203C30.2266 23.2109 30.5 24.1875 30.5 25.25V30.5H0.5ZM4.25 26.75H26.75V25.25C26.75 24.9062 26.6641 24.5938 26.4922 24.3125C26.3203 24.0312 26.0938 23.8125 25.8125 23.6562C24.125 22.8125 22.4219 22.1797 20.7031 21.7578C18.9844 21.3359 17.25 21.125 15.5 21.125C13.75 21.125 12.0156 21.3359 10.2969 21.7578C8.57812 22.1797 6.875 22.8125 5.1875 23.6562C4.90625 23.8125 4.67969 24.0312 4.50781 24.3125C4.33594 24.5938 4.25 24.9062 4.25 25.25V26.75ZM15.5 11.75C16.5312 11.75 17.4141 11.3828 18.1484 10.6484C18.8828 9.91406 19.25 9.03125 19.25 8C19.25 6.96875 18.8828 6.08594 18.1484 5.35156C17.4141 4.61719 16.5312 4.25 15.5 4.25C14.4688 4.25 13.5859 4.61719 12.8516 5.35156C12.1172 6.08594 11.75 6.96875 11.75 8C11.75 9.03125 12.1172 9.91406 12.8516 10.6484C13.5859 11.3828 14.4688 11.75 15.5 11.75Z" fill="#1868DB"/>
</svg>

  )
}

export default PersonIcon