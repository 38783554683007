import React, { useState } from "react";
import classes from "./PracticalAndCraftedApproach.module.scss";
import styles from "../../../DesignSystem/_classes.module.scss";
import Savings from "../../../assets/icons/Savings";
import Factory from "../../../assets/icons/Factory";
import Power from "../../../assets/icons/WaterElec";
import Legal from "../../../assets/icons/LawOrder";
import Policy from "../../../assets/icons/PoliceBadge";
import Card from "../../../Components/Card/Card";
import useIsMobile from "../../../Hooks/UseIsMobile";
import ClemVision from "../ClemVision/ClemVision";
import ClemEcosystem from "../ClemEcosystem/ClemEcosystem";

const PracticalAndCraftedApproach = () => {
  const isMobile = useIsMobile(950);
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>();

  const pacArr = [
    { header: "Financial", desc: "Institutions", svg: Savings },
    { header: "CFPP", desc: "Asset owners", svg: Factory },
    { header: "Power", desc: "Purchasers", svg: Power },
    { header: "Legal", desc: "Institutions", svg: Legal },
    { header: "Policy", desc: "Makers", svg: Policy },
  ];

  const mouseEnterHandler = (index: number) => () => {
    setHoveredIndex(index);
  };

  const mouseLeaveHandler = (index: number) => () => {
    setHoveredIndex(undefined);
  };

  return (
    <div className={classes["prac-crafted-approach-container"]}>    

      {/* Commented the below Components so that it can be used in future */}

      {/* {isMobile ? (
        <div className={classes["pc-text-image-container-mobile"]}>
          <div className={classes["pc-prac-text"]}>Practical and</div>
          <div className={classes["pc-crafted-approach-text"]}>
            Crafted Approach
          </div>
          <div className={classes["pc-content-para-1"]}>
            Coal-fired power generation remains a significant contributor to
            global carbon emissions, particularly in Asia, where a concentration
            of young CFPPs exists, averaging less than 15 years old. To address
            this pressing issue, CLEM, a first-of-its-kind clean energy
            transition, tailor-made solution, takes a practical data-driven
            approach.
          </div>
          <div className={classes["pc-content-para-2"]}>
            CLEM works with CFPP asset owners, power purchasers, and
            policymakers by connecting the dots to automate, simplify, and scale
            clean energy transitions.
          </div>
        </div>
      ) : (
        <div className={classes["pc-text-image-container"]}>
          <div className={classes["pc-content"]}>
            <div className={classes["pc-prac-text"]}>Practical and</div>
            <div className={classes["pc-crafted-approach-text"]}>
              Crafted Approach
            </div>
            <div className={classes["pc-content-para-1"]}>
              Coal-fired power generation remains a significant contributor to
              global carbon emissions, particularly in Asia, where a
              concentration of young CFPPs exists, averaging less than 15 years
              old. To address this pressing issue, CLEM, a first-of-its-kind
              clean energy transition, tailor-made solution, takes a practical
              data-driven approach.
            </div>
            <div className={classes["pc-content-para-2"]}>
              CLEM works with CFPP asset owners, power purchasers, and
              policymakers by connecting the dots to automate, simplify, and
              scale clean energy transitions.
            </div>
          </div>
          <div className={classes["pc-image"]}>
            <img
              src={
                "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/sunny-landscape-with-windmills.jpg"
              }
              height="100%"
              width="100%"
              alt="steppe.png"
              loading="lazy"
            ></img>
          </div>
        </div>
      )}
      <div
        className={`${classes["pc-cards-container"]} ${styles["hide-scrollbar"]}`}
      >
        {pacArr.map((element: any, index: number) => (
          <Card
            customClass="card-container"
            extraProps={{
              onMouseEnter: mouseEnterHandler(index),
              onMouseLeave: mouseLeaveHandler(index),
            }}
          >
            <div className={classes["card-content"]}>
              <div className={classes["card-icon"]}>
                <element.svg
                  color={
                    hoveredIndex === index && hoveredIndex !== undefined
                      ? "white"
                      : "#1868db"
                  }
                />
              </div>
              <div
                className={classes["card-header"]}
                style={{
                  color:
                    hoveredIndex === index && hoveredIndex !== undefined
                      ? "white"
                      : "#1868db",
                }}
              >
                {element.header}
              </div>
              <div
                className={classes["card-desc"]}
                style={{
                  color:
                    hoveredIndex === index && hoveredIndex !== undefined
                      ? "white"
                      : "black",
                }}
              >
                {element.desc}
              </div>
            </div>
          </Card>
        ))}
      </div> */}

      
      <ClemVision/>
      <div className={classes["pc-video-container"]}>
        <video
          src={
            "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/1669379_Wind+Turbine+Farm_Windpower_3840x2160.mp4"
          }
          autoPlay
          loop
          muted
          controls={false} /* If you want to hide controls */
          width="100%"
          height="100%"
        ></video>
      </div>
      <ClemEcosystem/>
    </div>
  );
};

export default PracticalAndCraftedApproach;
