import React from 'react'

const FactoryIcon = () => {
  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.75 38.25V15.7031L13.875 10.125V13.875L23.25 10.125V15.75H38.25V38.25H0.75ZM4.5 34.5H34.5V19.5H19.5V15.6563L10.125 19.4062V15.75L4.5 18.2344V34.5ZM17.625 30.75H21.375V23.25H17.625V30.75ZM10.125 30.75H13.875V23.25H10.125V30.75ZM25.125 30.75H28.875V23.25H25.125V30.75ZM38.25 15.75H28.875L30.75 0.75H36.375L38.25 15.75Z" fill="#1868DB"/>
    </svg>
    
  )
}

export default FactoryIcon