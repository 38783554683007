import React from 'react'

const GavelIcon = () => {
  return (
    <svg width="35" height="37" viewBox="0 0 35 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 36.375V32.625H23V36.375H0.5ZM11.0938 27.2813L0.5 16.6875L4.4375 12.6562L15.125 23.25L11.0938 27.2813ZM23 15.375L12.4062 4.6875L16.4375 0.75L27.0313 11.3438L23 15.375ZM31.625 34.5L7.15625 10.0313L9.78125 7.40625L34.25 31.875L31.625 34.5Z" fill="#1868DB"/>
</svg>

  )
}

export default GavelIcon