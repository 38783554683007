import React from 'react';

const arrowTypes = {
    up: {
        rotate: 0,
    },
    down: {
        rotate: 180,
    },
    left: {
        rotate: 270,
    },
    right: {
        rotate: 90,
    },
    inverted: {
        rotate: 50,
    },
};

type Props = {
    style?: React.CSSProperties;
    color?: React.CSSProperties['color'];
    height?: React.CSSProperties['height'];
    width?: React.CSSProperties['width'];
    arrowType?: keyof typeof arrowTypes;
    handleClick?: () => void;
};

const Arrow: React.FC<Props> = ({
    style,
    color = '#000',
    width = 10,
    height = 10,
    arrowType = 'left',
    handleClick,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                transform: `rotate(${arrowTypes[arrowType].rotate}deg)`,
                cursor: 'pointer',
                ...style,
            }}
            onClick={handleClick}
        >
            <g transform="rotate(90 6 6)">
                <path
                    d="M2.5431 6.74961L6.01393 10.4246C6.1556 10.5746 6.22348 10.7496 6.21758 10.9496C6.21167 11.1496 6.13789 11.3246 5.99622 11.4746C5.85456 11.6121 5.68928 11.684 5.50039 11.6902C5.3115 11.6965 5.14622 11.6246 5.00456 11.4746L0.329557 6.52461C0.258724 6.44961 0.20855 6.36836 0.179036 6.28086C0.149523 6.19336 0.134766 6.09961 0.134766 5.99961C0.134766 5.89961 0.149523 5.80586 0.179036 5.71836C0.20855 5.63086 0.258724 5.54961 0.329557 5.47461L5.00456 0.524609C5.13442 0.387109 5.29674 0.318359 5.49154 0.318359C5.68633 0.318359 5.85456 0.387109 5.99622 0.524609C6.13789 0.674609 6.20872 0.852734 6.20872 1.05898C6.20872 1.26523 6.13789 1.44336 5.99622 1.59336L2.5431 5.24961H10.4587C10.6594 5.24961 10.8276 5.32148 10.9634 5.46523C11.0992 5.60898 11.1671 5.78711 11.1671 5.99961C11.1671 6.21211 11.0992 6.39023 10.9634 6.53398C10.8276 6.67773 10.6594 6.74961 10.4587 6.74961H2.5431Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default Arrow;
