import React from "react";
import classes from "./ClemTransitions.module.scss";
import DescriptionCard from "../../Components/DescriptionCard/DescriptionCard";
import Savings from "../../assets/icons/Savings";
import FactoryIcon from "../../assets/icons/FactoryIcon";
import GavelIcon from "../../assets/icons/GavelIcon";
import WaterEc from "../../assets/icons/WaterEc";
import Button from "../../Components/Button/Button";
import useIsMobile from "../../Hooks/UseIsMobile";
import OurStrategy from "../LandingPage/OurStrategy/OurStrategy";

const cardDescription = [
  {
    heading: "Transition Financing",
    description:
      "Raising transition financing with CLEM strategy funds and partnering with debt and equity players.",
    icon: <Savings />,
  },
  {
    heading: "Clean Energy Development",
    description:
      "Partnering with stakeholders to share expertise with the goal of achieving the best outcomes while keeping the cost low.",
    icon: <FactoryIcon />,
  },
  {
    heading: "Blockchain - driven Marketplace",
    description:
      "Sourcing readily available and credible clean energy capacities and low cost of capital.",
    icon: <GavelIcon />,
  },
  {
    heading: "Additional Cashflows",
    description:
      "Transition credits; Monetizing excess power from RE; Repurposing CFPP decommissioning locations",
    icon: <WaterEc />,
  },
];

const ClemTransitions = () => {
  const isMobile = useIsMobile(950);

  return (
    <div className={classes["clem-transitiuon-page"]}>
      <div
        className={classes["hero-section-container"]}
        style={{
          backgroundImage: `url('https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/sunny-landscape-with-windmills.jpg')`,
        }}
      >
        CLEM Transitions
      </div>

      {/* <div className={classes["pc-video-container"]}>
        <video
          src={
            "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/1669379_Wind+Turbine+Farm_Windpower_3840x2160.mp4"
          }
          autoPlay
          loop
          muted
          controls={false}
          width="100%"
          height="100%"
        ></video>
      </div> */}

      <OurStrategy />

      <div className={classes["clem-stretegy-container"]}>
        <div className={classes["clem-stretegy-heading"]}>
          {" "}
          CLEM Strategy: <span> Make it Happen!</span>
        </div>
        <div className={classes["divider"]}></div>
        <div className={classes["clem-stretegy-description-container"]}>
          <div className={classes["clem-stretegy-description"]}>
            As part of its strategy, CLEM embraces a "make it happen" approach
            to ensure profitability. We achieve this through four key avenues.
          </div>
        </div>
        <DescriptionCard cardDescription={cardDescription} />
      </div>

      <div className={classes["get-in-touch-container"]}>
        {!isMobile && (
          <div className={classes["git-image-container"]}>
            <img
              src={
                "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/image.jpeg"
              }
              width={"100%"}
              height={"100%"}
              loading="lazy"
            ></img>
          </div>
        )}
        <div className={classes["git-card"]}>
          <div className={classes["git-card-content"]}>
            Ready to <span>start</span> your <span>journey</span> towards a{" "}
            <span>sustainable, clean energy</span> future ?
          </div>
          <div className={classes["separator"]}></div>
          <div className={classes["git-btn-container"]}>
            <Button
              text="Get in touch"
              btnClickHandler={() =>
                window.open(
                  `${process.env.REACT_APP_SE_URL}/contact-us`,
                  "_blank"
                )
              }
              btnStyles={{
                backgroundColor: "#111111",
                height: isMobile?"36px":"52px",
                fontSize: isMobile?"11px":"16px",
                padding:'5px',
                width: isMobile?"100px":"141px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClemTransitions;
