import React, { useState, useRef, useEffect } from "react";
import classes from "./RequestDemo.module.scss";
import contactUs from "../../assets/images/RequestDemo.svg";
import Button from "../../Components/Button/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAutosizeTextArea from "../ContactUs/useAutoResizeTextArea";
import * as CompanyEmailValidator from "company-email-validator";

const keyToLabelMapping = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Corporate Email",
  company: "Company",
  country: "Country / Region",
  industry: "Industry",
};

const requiredFields = [
  "firstName",
  "lastName",
  "email",
  "country",
  "industry",
];

function RequestDemo() {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    message: "",
    country: "",
    industry: "",
  });

  const [checked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);


  useEffect(() => {
    document.title =
      "Contact Us | Sustainability Economics - Low-Carbon Economy";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Unlock low-carbon economy opportunities with Sustainability Economics. Contact us today, and our team will get back to you promptly."
      );
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Unlock low-carbon economy opportunities with Sustainability Economics. Contact us today, and our team will get back to you promptly.";
      document.head.appendChild(meta);
    }

    return () => {
      document.title = "Default Title";  
    };
  }, []);

  const handleChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = evt.target || {};

    setIsSubmitted(false);
    setErrors((prev: Record<string, string>) => ({
        ...prev,
        [name]: "",  
      }));
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    const newErrors: Record<string, string> = {};

    if (!emailRegex.test(formValues.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!CompanyEmailValidator.isCompanyEmail(formValues.email)) {
      newErrors.email = "Please enter a corporate email";
    }


    requiredFields.forEach((field) => {
      if (!formValues[field as keyof typeof formValues]) {
        newErrors[field] = `${
          keyToLabelMapping[field as keyof typeof keyToLabelMapping]
        } is required`;
      }
    });

    if (!formValues.country) {
      newErrors.country = "Country / Region is required";
    }

    if (!formValues.industry) {
      newErrors.industry = "Industry is required";
    }

    if (!checked) {
      newErrors.privacyPolicy = "Please accept the privacy policy";
    }

    setErrors(newErrors);

    if (Object.values(newErrors).every((val) => !val)) {
      setIsLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const sendData = async () => {
        await fetch(
          "https://6f4k8wcei8.execute-api.ap-south-1.amazonaws.com/prod/api/v1/clem/contactus",
          {
            method: "POST",
            body: JSON.stringify({
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              email: formValues.email,
              company: formValues.company,
              message: formValues.message,
              country: formValues.country,
              industry: formValues.industry,
              is_agreed: checked,
            }),
            headers: myHeaders,
          }
        )
          .then((res) => {
            if (res.status === 200) {
              setIsSubmitted(true);
            } else {
              throw new Error("Error while submitting data");
            }
          })
          .catch((err) => {
            toast.error(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

      sendData();
    }
  };

  return (
    <div className={classes.container}>
    <div className={classes.header}>
      <img
        className={classes["header-img"]}
        src={contactUs}
        alt="Contact Us"
      />
      <div className={classes.title}>Request Demo</div>
    </div>
  
    <div className={classes["contact-details"]}>
      <div className={classes.title}>Request Demo</div>
      <div className={classes.divider} />
  
      <p className={`${classes["inner-container"]} ${classes.text}`}>
        Please submit your contact request using the contact form below and select the nature of your request (Professional investor enquiry, Investment proposal, Complaint, or Other). Your personal data will be handled with utmost care and in full compliance. You can consult our Privacy & Data Protection Policy for further details.
      </p>
  
      <div className={classes.divider} />
  
      <form className={`${classes.form} ${classes["inner-container"]}`}>
        <div className={classes.row}>
          <div className={classes["field-holder"]}>
            <input
              type="text"
              id="First Name"
              placeholder="Enter"
              name="firstName"
              value={formValues.firstName}
              onChange={handleChange}
            />
            <label htmlFor="First Name" className={classes.label}>
              First Name<sup>*</sup>
            </label>
            <p className={`${classes.error} ${errors.firstName ? classes.show : ""}`}>
              {errors.firstName || ""}
            </p>
          </div>
  
          <div className={classes["field-holder"]}>
            <input
              type="text"
              id="Last Name"
              placeholder="Enter"
              name="lastName"
              value={formValues.lastName}
              onChange={handleChange}
            />
            <label htmlFor="Last Name" className={classes.label}>
              Last Name<sup>*</sup>
            </label>
            <p className={`${classes.error} ${errors.lastName ? classes.show : ""}`}>
              {errors.lastName || ""}
            </p>
          </div>
        </div>
  
        <div className={classes.row}>
          <div className={classes["field-holder"]}>
            <input
              type="email"
              id="Email"
              placeholder="Enter"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
            <label htmlFor="Email" className={classes.label}>
              Corporate Email<sup>*</sup>
            </label>
            <p className={`${classes.error} ${errors.email ? classes.show : ""}`}>
              {errors.email || ""}
            </p>
          </div>
        </div>
  
        <div className={classes["field-holder"]}>
          <input
            type="text"
            id="Company"
            placeholder="Enter"
            name="company"
            value={formValues.company}
            onChange={handleChange}
          />
          <label htmlFor="Company" className={classes.label}>
            Company
          </label>
        </div>
  
        <div className={classes["field-holder"]}>
          <input
            type="text"
            id="Country"
            placeholder="Enter"
            name="country"
            value={formValues.country}
            onChange={handleChange}
          />
          <label htmlFor="Country" className={classes.label}>
            Country / Region<sup>*</sup>
          </label>
          <p className={`${classes.error} ${errors.country ? classes.show : ""}`}>
            {errors.country || ""}
          </p>
        </div>
  
        <div className={classes["field-holder"]}>
          <input
            type="text"
            id="Industry"
            placeholder="Enter"
            name="industry"
            value={formValues.industry}
            onChange={handleChange}
          />
          <label htmlFor="Industry" className={classes.label}>
            Industry<sup>*</sup>
          </label>
          <p className={`${classes.error} ${errors.industry ? classes.show : ""}`}>
            {errors.industry || ""}
          </p>
        </div>
  
        <div className={`${classes["field-holder"]} ${classes["textarea-container"]}`}>
          <textarea
            ref={textAreaRef}
            id="message"
            rows={6}
            placeholder="Enter"
            name="message"
            value={formValues.message}
            onChange={handleChange}
          />
          <label htmlFor="message" className={classes.label}>
            Message
          </label>
        </div>
  
        <div className={classes["btn-container"]}>
          <div className={classes["checkbox-container"]}>
            <input
              type="checkbox"
              id="accept"
              className={classes.checkbox}
              checked={checked}
              onChange={() => {
                setErrors((prev) => ({
                  ...prev,
                  privacyPolicy: "",
                }));
                setIsChecked(!checked);
              }}
            />
            <p className={`${classes.text} ${classes.text1}`}>
              I agree to the privacy policy, including CLEM using my contact
              details to contact me for marketing purposes.<sup>*</sup>
              <p className={`${classes.error} ${errors.privacyPolicy ? classes.show : ""}`}>
              {errors.privacyPolicy || ""}
            </p>
            </p>
           
          </div>
  
          <Button
            text={
              isLoading
                ? "Submitting..."
                : isSubmitted
                ? "Submitted"
                : "Submit"
            }
            btnClickHandler={handleSubmit}
            className={`${classes.btn} ${isSubmitted ? classes["btn-submitted"] : ""} ${
              isLoading ? classes["btn-disabled"] : ""
            }`}
          />
        </div>
      </form>
    </div>
  </div>
  
  );
}

export default RequestDemo;
