import React from "react";
import classes from "./ClemEnablement.module.scss";
import DescriptionCard from "../../Components/DescriptionCard/DescriptionCard";
import { ReactComponent as SavingsIcon } from "../../assets/icons/savings.svg";
import Button from "../../Components/Button/Button";
import useIsMobile from "../../Hooks/UseIsMobile";
import WindPowerIcon from "../../assets/icons/WindPowerIcon";
import BackpackIcon from "../../assets/icons/BackpackIcon";
import PersonIcon from "../../assets/icons/PersonIcon";

type Props = {};
const cardDescription = [
  {
    heading: "Financial Institutions",
    description: "Enhance bankability through increased profits powered by AI.",
    icon: <SavingsIcon />,
  },
  {
    heading: "Renewable Energy Developers",
    description:
      "We enhance profitability through additional cashflows powered by AI.",
    icon: <WindPowerIcon />,
  },
  {
    heading: "Storage",
    description:
      "Optimise battery operations to maximise profitability powered by AI.",
    icon: <BackpackIcon />,
  },
  {
    heading: "Consumer",
    description:
      "Manage cost & enhance profitability with customised power contracts that achieve climate goals, powered by AI.",
    icon: <PersonIcon />,
  },
];
const ClemEnablement = (props: Props) => {
  const isMobile = useIsMobile(950);

  return (
    <div className={classes["wrapper"]}>
      <div className={classes["header"]}>
        <img
          className={classes["header-img"]}
          src="https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/openart-image_o28ZElza_1731565742447_raw+1.jpg"
          alt="CLEM Enablement"
        />

        <div className={classes["title"]}>CLEM Enablement</div>
      </div>
      <div className={classes["re-tech-card-wrapper"]}>
        <div className={classes["re-tech-card"]}>
          <span className={classes["blueText"]}>20-30%&nbsp;</span>
          of power produced by renewable energy is currently not monetised due
          to its&nbsp;
          <span className={classes["blue-text"]}>
            inherent intermittency&nbsp;
          </span>{" "}
          and&nbsp;
          <span className={classes["blue-text"]}>curtailment&nbsp;</span> to
          prevent overloads and grid stability.
        </div>
        <img
          className={classes["windmill-img"]}
          src="https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/pexels-wolfgang-weiser-467045605-29002580-Photoroom+1.png"
          alt="windmill"
        />
      </div>
      <div className={classes["clem-ai-wrapper"]}>
        <div className={classes["clem-ai-title"]}>
          CLEM.ai <span className={classes["blue-text"]}>Vision</span>
        </div>
        <div className={classes["divider"]}></div>
        <div className={classes["clem-ai-img-wrapper"]}>
          <img
            className={classes["clem-ai-img"]}
            src="https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/red-zeppelin-UVGE-o757-g-unsplash.jpg"
            alt="CLEM Enablement"
          />

          <div className={classes["clem-ai-title"]}>
            CLEM.ai optimises the economic use of&nbsp;
            <span className={classes["bold-text"]}>every MegaWatt</span>
            &nbsp;produced, paving the way for a&nbsp;
            <span className={classes["bold-text"]}>
              greener future powered by AI.
            </span>
          </div>
        </div>
      </div>
      <div className={classes["clem-stretegy-container"]}>
        <div className={classes["clem-stretegy-heading"]}>Who We Help</div>
        <div className={classes["divider"]}></div>
        <div className={classes["clem-stretegy-description-container"]}>
          <div className={classes["clem-stretegy-description"]}>
            By leveraging CLEM.ai, we deliver transformative solutions that
            redefine profitability for all our stakeholders, turning clean
            energy opportunities into success stories.
          </div>
        </div>
        <DescriptionCard cardDescription={cardDescription} />
      </div>
      <div className={classes["get-in-touch-container"]}>
        {!isMobile && (
          <div className={classes["git-image-container"]}>
            <img
              src={
                "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/image.jpeg"
              }
              width={"100%"}
              height={"100%"}
              loading="lazy"
            ></img>
          </div>
        )}
        <div className={classes["git-card"]}>
          <div className={classes["git-card-content"]}>
            Ready to <span>start</span> your <span>journey</span> towards a{" "}
            <span>sustainable, clean energy</span> future ?
          </div>
          <div className={classes["separator"]}></div>
          <div className={classes["git-btn-container"]}>
          <Button
              text="Get in touch"
              btnClickHandler={() =>
                window.open(
                  `${process.env.REACT_APP_SE_URL}/contact-us`,
                  "_blank"
                )
              }
              btnStyles={{
                backgroundColor: "#111111",
                height: isMobile?"36px":"52px",
                fontSize: isMobile?"11px":"16px",
                padding:'5px',
                width: isMobile?"100px":"141px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClemEnablement;
