import React from "react";
import LandingPage from "../LandingPage/LandingPage";
import AboutUs from "../AboutUs/AboutUs";
import ContactUs from "../ContactUs/ContactUs";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import RequestDemo from "../RequestDemo/RequestDemo";
import ClemTransitions from "../ClemTransitions/ClemTransitions";
import ClemEnablement from "../ClemEnablement/ClemEnablement";

type typeRoutesConfig = {
  path?: string | undefined;
  component: React.ElementType;
};

export const routes: typeRoutesConfig[] = [
  {
    path: "/",
    component: LandingPage,
  },
  {
    path: "/Aboutus",
    component: AboutUs,
  },
  {
    path: "/request-demo",
    component: RequestDemo,
  },
  {
    path: "/terms",
    component: TermsAndConditions,
  },
  {
    path: "/enablement",
    component: ClemEnablement,
  },
  {
    path: "/transitions",
    component: ClemTransitions,
  },
];
