import React from "react";
import classes from "./GetInTouch.module.scss";
import Button from "../../../Components/Button/Button";
import useIsMobile from "../../../Hooks/UseIsMobile";
import { useNavigate } from "react-router-dom";

const GetInTouch = () => {
  const isMobile = useIsMobile(950);

  return (
    <div className={classes["get-in-touch-container"]}>
      {!isMobile && (
        <div className={classes["git-image-container"]}>
          <img
            src={
              "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/image.jpeg"
            }
            width={"100%"}
            height={"100%"}
            loading="lazy"
          ></img>
        </div>
      )}
      <div className={classes["git-card"]}>
        <div className={classes["git-card-content"]}>
          Ready to <span>start</span> your <span>journey</span> towards a{" "}
          <span>sustainable, clean energy</span> future ?
        </div>
        <div className={classes["separator"]}></div>
        <div className={classes["git-btn-container"]}>
          <Button
            text="Get in touch"
            btnClickHandler={() =>
              window.open(
                `${process.env.REACT_APP_SE_URL}/contact-us`,
                "_blank"
              )
            }
            btnStyles={{
             
              backgroundColor: "#111111",
                height: isMobile?"36px":"52px",
                fontSize: isMobile?"11px":"16px",
                padding:'5px',
                width: isMobile?"100px":"141px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
