import React, { useState } from 'react';
import classes from './DescriptionCard.module.scss';

type CardDescription = {
  heading: string;
  description: string;
  icon: any;
};

type DescriptionCardProps = {
  cardDescription: CardDescription[];
};

const DescriptionCard: React.FC<DescriptionCardProps> = ({ cardDescription }) => {
    const [onHoverIndex, setOnHoveredIndex] = useState<number | null>(null);
    const [onHover, setOnHover] = useState(false);


    const mouseEnterHandler = (receivedIndex: number) => () => {
      setOnHoveredIndex(receivedIndex)
      setOnHover(true)
    }

    const mouseLeaveHandler = () => {
      setOnHoveredIndex(null)
      setOnHover(false)
    }



  return (
    <div className={classes['description-card-container']} >
      {cardDescription.map((card, index) =>{
        
        return (
        <div key={index} className={classes['card']} onMouseEnter={mouseEnterHandler(index)} onMouseLeave={mouseLeaveHandler}>
          <div className={( onHover && onHoverIndex=== index) ? `${classes['icon-container']} ${classes['icon-container-hovered']}` : classes['icon-container']}>
            {card.icon}
          </div>
          <div className={classes['heading-container']}>{card.heading}</div>
          <div className={classes['description-container']}>{card.description}</div>
        </div>
      )})}
    </div>
  );
};

export default DescriptionCard;