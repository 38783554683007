import React from 'react'

const BackpackIcon = () => {
  return (
    <svg width="31" height="39" viewBox="0 0 31 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.25 38.25C3.21875 38.25 2.33594 37.8828 1.60156 37.1484C0.867188 36.4141 0.5 35.5312 0.5 34.5V12C0.5 10.25 1.03125 8.71875 2.09375 7.40625C3.15625 6.09375 4.5 5.21875 6.125 4.78125V0.75H11.75V4.5H19.25V0.75H24.875V4.78125C26.5 5.21875 27.8438 6.09375 28.9063 7.40625C29.9688 8.71875 30.5 10.25 30.5 12V34.5C30.5 35.5312 30.1328 36.4141 29.3984 37.1484C28.6641 37.8828 27.7812 38.25 26.75 38.25H4.25ZM4.25 34.5H26.75V12C26.75 10.9688 26.3828 10.0859 25.6484 9.35156C24.9141 8.61719 24.0312 8.25 23 8.25H8C6.96875 8.25 6.08594 8.61719 5.35156 9.35156C4.61719 10.0859 4.25 10.9688 4.25 12V34.5ZM20.1875 27H23.9375V19.5H7.0625V23.25H20.1875V27Z" fill="#1868DB"/>
</svg>

  )
}

export default BackpackIcon